
import { Loadable, constants } from '../../lib';
import { SignatureType } from './types';

const { SignatureClsPrefix } = constants;
const Signature = Loadable({
  name: 'Signature',
  loader: () => import(
    /* webpackChunkName: "signature" */
    './Signature'
  )
}) as SignatureType;

Signature.defaultProps = {
  prefixCls: SignatureClsPrefix,
  type: 'small',
  mode: 'name',
  showDelete: true,
  showScaleControl: true,
  qrcodeMode: 'tab'
};

export type { SignatureProps, SignatureType } from './types';


export default Signature;
