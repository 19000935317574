import { appInfo, historyUtil } from '@weapp/utils';
import { Component } from 'react';
import MainLayout from './MainLayout';
import ModuleContent from './ModuleContent';
import { RootLayoutProps, RootLayoutState } from './types';
import { getPathMatchedConfig, getPluginEnableConfig, RenderType } from './RootLayoutUtils';

class RootLayout extends Component<RootLayoutProps, RootLayoutState> {

  unlistener: any = null;
  plugin: any = [];

  constructor(props: RootLayoutProps) {
    super(props);
    this.state = {
      module: null,
    };
    this.doInit();
  }

  doInit() {
    const { onInit } = this.props;
    // request({ url: '/api/getRootLayoutPlugins' }).then(res => {
    //   this.plugin = res; // 获取到插件配置
    // });
    const plugins = onInit?.() || [];
    this.plugin = this.plugin.concat(plugins);
  }

  getModuleName (locationPath: string) {
    locationPath = locationPath.replace(/^\/(splayout|sp|mobile)/, '');
    let moduleName = locationPath.split('/')[1];
    if (moduleName === 'ui' || moduleName === 'utils') {
      return '';
    } else if (moduleName === 'workflows') {
      moduleName = 'workflow';
    } else if (moduleName === 'free') {
      moduleName = 'cusapp';
    } else if (moduleName === 'info') {
      // 后台管理中心模块映射
      moduleName = 'manager';
    }
    // no.3230623 新增调度中心ebdapp/view路由加载ebdapp仓库的逻辑 0905去除该逻辑
    // else if (moduleName === 'ebdapp') {
    //   moduleName = 'ebdapp';
    //   // 二次判断 属于ebdapp的情况下
    //   let secondModule = locationPath.split('/')[2]; 
    //   if (secondModule === 'view') {
    //     moduleName = 'cusapp';
    //   } 
    // } 

    // feat: no.2455143 新增配合菜单模块调整调度中心模块匹配标识 1109 菜单模块说目前只能在test 不能全量合并 11月16号还原
    // feat: no.2455143 新增配合菜单模块调整调度中心模块匹配标识 0920 配合删除该逻辑
    //  else if (moduleName === 'analyze') { 
    //   moduleName = 'statpage';
    // }
    return moduleName;
  }

  // 路由规则匹配
  getRenderConfig (location: Location) {
    const { basename, menu: menuData, moduleName: module } = this.props;
    const { pathname } = location || {};
    let locationPath = pathname;
    // 匹配菜单
    if (menuData) {
      const matchedMenuData = getPathMatchedConfig(menuData, locationPath);
      if (matchedMenuData?.length) {
        return ({
          renderType: RenderType.MenuCustomRender,
          menuConfig: matchedMenuData,
        });
      }
    }

    // 匹配插件
    if (this.plugin) {
      const matchedPluginData = getPathMatchedConfig(this.plugin, locationPath);
      if (matchedPluginData?.length) {
        return ({
          renderType: RenderType.PluginCustomRender,
          pluginConfig: matchedPluginData,
        });
      }
    }

     // TODO: 处理二级域名和二级路径
     const layoutPublicUrl = appInfo(module!).publicUrl || basename;
     if(layoutPublicUrl){
       const reg = new RegExp(`^${layoutPublicUrl}`);
       if (locationPath.match(reg)) {
         locationPath = locationPath.replace(reg, '');
       }
     }

    // TODO: 兼容老地址

    // ecodesdk
    if (/^\/custom\//.test(locationPath)) {
      return ({
        renderType: RenderType.EcodeCustomRender,
      });
    }

    // 一级路由匹配
    const moduleName = this.getModuleName(locationPath);
    if (moduleName) {
      return ({
        renderType: RenderType.ModuleMainRouteRender,
        app: `@weapp/${moduleName}`,
      });
    }

    // 404
    return ({
      renderType: RenderType.OtherRender,
      errorType: '404',
    });
  }

  render() {
    const { menu, ModuleWrapComponent, children, ...restProps } = this.props;

    const globalHistory = historyUtil.getMainHistory();
    const renderConfig = this.getRenderConfig((globalHistory?.location || window.location) as Location);
    const pluginConfig = getPluginEnableConfig(this.plugin);

    let moduleContent: React.ReactNode = (
      <ModuleContent
        weId={`${this.props.weId || ''}_pg9g5g`}
        renderConfig={renderConfig}
        pluginConfig={pluginConfig}
        ModuleWrapComponent={ModuleWrapComponent}
      />
    );

    return (
      <div>
        <MainLayout weId={`${this.props.weId || ''}_z2iyy1`} {...restProps} moduleContent={moduleContent}>
          {children}
        </MainLayout>
      </div>
    );
  }
}

export default RootLayout;
