import { observable, action } from "mobx";
import { RequestOptionConfig, getLabel } from '@weapp/utils';
import { Dialog, DialogMessageProps, ITableColumn, AnyObj } from '../../../lib';
import { OperatesType } from '../types';
import BaseStore from './BaseStore';
import { getStore } from "./store/index";

const batchKey = ['pageSize', 'current', 'total', 'data', 'loading'];
export default class Store<Data = any> extends BaseStore {

  // table 配置
  @observable rowKey: string = 'id';
  @observable columns: ITableColumn[] = [];
  @observable data: ReadonlyArray<Data> = [];

  // 操作列配置
  // key 优先于 id
  @observable operates: OperatesType[] = [{
    text: getLabel('31479', '编辑'),
    index: 1,
    id: 'edit',
    key: 'edit',
    visible: true,
    outer: false
  }, {
    text: getLabel('21778', '删除'),
    index: 2,
    id: 'delete',
    key: 'delete',
    visible: true,
    outer: false
  }, {
    text: getLabel('119248', '移至最前'),
    index: 3,
    id: 'top',
    key: 'top',
    visible: true,
    outer: false
  }];

  @observable paginationType: string = 'part';  // 分页模式 'part' | 'more' | 'scroll'

  // 是否展示搜索框（后端暂未支持搜索功能，默认关闭）
  @observable showSearch: boolean = false;
  @observable searchType: 'quick' | 'default' = 'default';

  /** 批量操作 */
  // 已选内容
  @observable checkedKeys: string[] = [];
  @observable selectedRows: any[] = [];
  @observable tableType: string = 'NONE';  // NONE\RADIO\CHECKBOX
  // 分页配置
  @observable pageSize_batch: number = 10;
  @observable current_batch: number = 1;
  @observable total_batch: number = 0;
  // 批量操作配置
  @observable data_batch: ReadonlyArray<Data> = [];
  // 批量加载弹框加载状态
  @observable loading_batch: boolean = false;

  /** 按钮隐藏时显示状态（禁用/隐藏）- 通用 */
  @observable btnVisibleType?: string = 'disabled';  // disabled / hidden
  /** 指定按钮隐藏时显示状态（禁用/隐藏) - 该设置会覆盖 btnVisibleType */
  // 由于之前 btnVisibleType 对 top 不生效，修复了 btnVisibleType 的问题后可能对已使用的模块造成影响，因此新增一个参数
  @observable addVisibleType?: string = '';
  @observable deleteVisibleType?: string = '';
  @observable topVisibleType?: string = 'hidden';


  @action init = (allDatas: Partial<Store>) => {
    this.setState({
      ...allDatas,
      isInit: true,
    })
  }

  // 其他
  @action
  setState = (params: any, callback?: () => void) => {
    Object.keys(params).forEach((key) => {
      if (key in this) {
        if (params.setBatchState && batchKey.includes(key)) {
          // 针对批量更新 store
          // @ts-ignore
          this[key + '_batch'] = params[key];
        } else {
          // @ts-ignore
          this[key] = params[key];
        }
      }
    });
    callback && callback();
  }

  @action
  getData = async (listConfig?: RequestOptionConfig, type?: 'add' | 'batch') => {
    return getStore().then((defaultModule: any) => defaultModule?.getData(this, listConfig, type))
  }

  @action
  searchData = (params: AnyObj) => {
    const { keywords, targetType, targetValue } = params;
    const prevSearchValue = this.searchValue;
    const prevSearchTargetType = this.searchTargetType;
    // const prevSearchTargetValue = this.searchTargetValue; 
    const prevSearchTargetId = this.searchTargetId; 
    const targetId = targetValue?.map?.((item: any) => item.id)?.join(',') || '';
    let needResetCurrent = false;
    let reqParams = {};
    if(this.searchType === 'default') {
      needResetCurrent = prevSearchTargetType !== targetType || targetId !== prevSearchTargetId;
      reqParams = {
        targetType,
        targetId,
      }
    } else {
      needResetCurrent = prevSearchValue !== keywords;
      reqParams = {
        keywords
      }
    }

    this.setState({
      searchValue: keywords, 
      searchTargetType: targetType, 
      searchTargetValue: targetValue,
      searchTargetId: targetId,
    }, () => {
      // 调用数据请求
      this.getData({
        url: this.listConfig.url,
        method: this.listConfig.method,
        params: {
          ...this.listConfig.params,
          current: needResetCurrent ? 1 : this.current,
          pageSize: this.pageSize,
          ...reqParams
        }
      })
    })
  }

  @action
  batchSearchData = (params: AnyObj) => {
    const { keywords, targetType, targetValue } = params;
    const prevSearchValue = this.searchValue_batch;
    const prevSearchTargetType = this.searchTargetType_batch;
    const prevSearchTargetId = this.searchTargetId_batch; 
    const targetId = targetValue?.map?.((item: any) => item.id)?.join(',') || '';
    let needResetCurrent = false;
    let reqParams = {};
    if(this.searchType === 'default') {
      needResetCurrent = prevSearchTargetType !== targetType || targetId !== prevSearchTargetId;
      reqParams = {
        targetType,
        targetId,
      }
    } else {
      needResetCurrent = prevSearchValue !== keywords;
      reqParams = {
        keywords
      }
    }
    this.setState({ 
      searchValue_batch: keywords, 
      searchTargetType_batch: targetType, 
      searchTargetValue_batch: targetValue,
      searchTargetId_batch: targetId,
    }, () => {
      // 调用数据请求
      this.getData({
        url: this.listConfig.url,
        method: this.listConfig.method,
        params: {
          ...this.listConfig.params,
          current: needResetCurrent ? 1 : this.current_batch,
          pageSize: this.pageSize_batch,
          ...reqParams
        }
      }, 'batch')
    })
  }

  /** 删除 */
  @action
  deleteReq = async (deleteConfig?: RequestOptionConfig, datas?: AnyObj[]) => {
    return getStore().then((defaultModule: any) => defaultModule?.deleteReq(this, deleteConfig, datas))
  }

  /** 批量删除 */
  @action
  batchDeleteReq = async (callback?: () =>  void) => {
    return getStore().then((defaultModule: any) => defaultModule?.batchDeleteReq(this, callback))
  }

  @action
  detailReq = async (detailConfig: RequestOptionConfig) => {
    return getStore().then((defaultModule: any) => defaultModule?.detailReq(this, detailConfig))
  }

  @action
  authSave = async (saveConfig?: RequestOptionConfig, formDatas?: any, callBack?: () => void) => {
    return getStore().then((defaultModule: any) => defaultModule?.authSave(this, saveConfig, formDatas, callBack))
  }

  @action
  authReq = async (authConfig: RequestOptionConfig) => {
    return getStore().then((defaultModule: any) => defaultModule?.authReq(this, authConfig))
  }

  @action
  sortReq = async (params?: AnyObj, newDatas?: any[]) => {
    return getStore().then((defaultModule: any) => defaultModule?.sortReq(this, params, newDatas))
  }

  @action
  batchSortReq = async (params?: AnyObj, newDatas?: any[], callback?: () => void) => {
    return getStore().then((defaultModule: any) => defaultModule?.batchSortReq(this, params, newDatas, callback))
  }


  getRealConfig = (config: RequestOptionConfig) => {
    const newConfig: RequestOptionConfig = {
      url: config.url?.replace('{module}', this.module),
      method: config.method,
    }

    let params = Object.assign({}, {
      sourceId: this.id,
      permissionId: this.permissionId,
      permissionType: this.permissionType,
      sourceType: this.sourceType
    }, config.params);
    if (config.method === 'get' || config.method === 'GET') {
      newConfig.params = params;
    } else if (config.method === 'post' || config.method === 'POST') {
      newConfig.data = params;
    }

    return newConfig
  }

  message = (options: DialogMessageProps) => {
    if (!this.triggerMessage && options.type === 'success') return;
    Dialog.message(options)
  }
}

export type ShareStoreType = Pick<Store, keyof Store>;