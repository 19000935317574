import { ua } from '@weapp/utils';
import { AnyObj } from '../../../types/common';
import { RuleItemsTable } from '../store/Store';
import { FormLayoutProps, FormInitAllDatas } from '../../../lib';
import { isMultiDatePickerType } from '../../date-picker/utils';
import { isObservableArray } from 'mobx';

export const isCustomLayoutEmpty = (customLayout: AnyObj) => {
  let empty = true;
  if (customLayout && Object.prototype.toString.call(customLayout) === '[object Object]') {
    const cusLayoutKeys = Object.keys(customLayout);
    const cusLayoutLens = cusLayoutKeys.length;
    for (let i = 0; i < cusLayoutLens; i++) {
      empty = customLayout[cusLayoutKeys[i]].length === 0;
      if (empty) break;
    }
  }
  return empty;
};

export const queueAnimProps = ua.os === 'iOS' ? { delay: 100 } : {};

export const getInitField = (col: FormLayoutProps, allDatas: FormInitAllDatas, isMobile: boolean) => {
  const temp = {
    ...col,
    id: col.id,
    title: col.label,
    content: col.label,
    hide: col.hide || false,
    ruleItemsTable: {},
    enableCheckDateValueType: false,
  };
  /* ---------  常用筛选老数据兼容规则 ---------- */
  const ruleItemsTable: RuleItemsTable = {};
  if (temp.items) {
    temp.items.forEach((itemKey) => {
      const tempItem = allDatas.items[itemKey];
      if (!tempItem) return;
      const customParameterName = tempItem?.customParameterName;
      if (customParameterName) ruleItemsTable[customParameterName] = itemKey;
      if ((isMobile && tempItem.itemType === 'DATETIMEPICKER' && (tempItem.isRange || tempItem.otherParams?.isRange) && (tempItem.inSearchAdvanced || tempItem.otherParams?.inSearchAdvanced)) || (!isMobile && tempItem.itemType === 'DATETIMEPICKER' && (tempItem.isRange || tempItem.otherParams?.isRange)) || (!isMobile && tempItem.itemType === 'DATEPICKER' && (tempItem.isRange || tempItem.otherParams?.isRange || isMultiDatePickerType(tempItem.type) || isMultiDatePickerType(tempItem.otherParams?.type)))) {
        temp.enableCheckDateValueType = true; // 包含日期区间组件
      }
    });
  }
  temp.ruleItemsTable = ruleItemsTable;
  return temp;
};

/** 获取当前layout中所有items字段，包含级联关系中默认不展示的 */
export const getItems = (col: FormLayoutProps) => {
  let items = [...col.items];
  if (col.cascadeRules) {
    Object.values(col?.cascadeRules)?.forEach((value) => {
      value &&
        Object.values(value)?.forEach((vals) => {
          const val = vals as any;
          if (Array.isArray(val) || isObservableArray(val)) {
            items = items.concat([...val]);
          } else if (val?.items && (Array.isArray(val?.items) || isObservableArray(val?.items))) {
            items = items.concat([...val?.items]);
          }
        });
    });
  }
  return Array.from(new Set(items));
};

export const isInput = (item: any) => {
  return item?.itemType === 'INPUT' || item?.itemType === 'INPUTNUMBER' || item?.itemType === 'PASSWORD';
};

//is-focus + 排除em
const exceptElement = ['ui-cascader-picker-focused','ui-select is-opened','is-focus','ql-editor'];
export const hasExceptElements = (className:string = '') => {
  return exceptElement.some((item) => className.includes(item));
};

//排除一些例如时间选择器，颜色选择器之类的组件的点开效果
export const hasPopInnerVis = (ref?:any)=>{
  let inner:any = document.getElementsByClassName('ui-trigger-popupInner')
  let hasPop = false
  let inPop = false
  if(!inner.length) return false
  for (let index = 0; index < inner.length; index++) {
    if(!inner[index].className.includes('ui-trigger-popupInner-hidden')){
      hasPop = true
      if(inner[index]?.contains?.(ref)){
        inPop = true
      }
    }
  }
  inner = null
  return hasPop&&!inPop
}
