
import { history, request, ua } from "@weapp/utils";
import { SystemWMSetting } from "./types";
import { watermarkSDK } from './index';
import { AnyObj } from "../../types/common";

// 系统水印设置
class WatermarkSetting {

  wmWhiteBlackListPromise: Promise<AnyObj>;

  /* 水印黑白名单 */
  wmWhiteBlackList: AnyObj | null = null;

  /* 路由取消监听函数 */
  unlistener: (() => void) | null = null;
  
  constructor () {
    this.wmWhiteBlackListPromise = this.getWMWhiteBlackList();
    this.loadSystemWatermark();
  }

  // 获取全局配置
  getGlobalConfig = () => {
    return window?.TEAMS?.globalConfig;
  }

  // 获取系统水印配置
  getSystemSetting = () => {
    let wmSetting = this.getGlobalConfig();
    if (wmSetting) {
      wmSetting = watermarkSDK.formatWMSetting(wmSetting);
    }
    return wmSetting;
  }

  // 清除水印
  destory = () => {
    watermarkSDK.clear();
  };

  /* 设置水印 */
  setWMOptions = (wmSetting?: SystemWMSetting) => {
    if (!wmSetting) return ;
    const options = watermarkSDK.formatWMSetting(wmSetting);
    // 根据配置初始化水印
    watermarkSDK.set(options);
  }

   // 是否开启了系统水印
   isWMOpen = () => {
    const wmSetting = this.getGlobalConfig();
    if (wmSetting) {
      const { wmUse, wmUseMobile } = wmSetting;
      const isMobile = ua.device === 'Mobile';
      return (isMobile ? wmUseMobile : wmUse) === '1';
    }
    return false;
  }

  getWMWhiteBlackList = () => {
    if (this.wmWhiteBlackListPromise) return this.wmWhiteBlackListPromise;
    if (!window.TEAMS?.currentUser || !this.isWMOpen()) return Promise.resolve({});
    return new Promise<AnyObj>((resolve) => {
      request({
        url: '/api/bcw/watermarke/queryWatermarkConfig'
      }).then(res => {
        console.log(res);
        if (res?.data) {
          this.wmWhiteBlackList = { ...res.data } as AnyObj;
          resolve(this.wmWhiteBlackList);
        }
      }).catch(err => {
        console.error(err);
        this.wmWhiteBlackList = {};
        resolve(this.wmWhiteBlackList); // 接口请求失败，当作未设置处理
      });
    });
  }

  // 加载系统水印
  loadSystemWatermark = () => {
    const wmSetting = this.getGlobalConfig();
    if (!wmSetting) {
      this.destory();
      return;
    }
    this.wmWhiteBlackListPromise.finally(() => {
      const {
        pagePageList, pageIpList, pageResourceList,
        pagePageWhitelist, pagePageBlacklist,
        pageIpWhitelistContain, pageIpBlacklistContain,
        pageResourceWhitelistContain, pageResourceBlacklistContain,
      } = this.wmWhiteBlackList || {};
      // 处理水印黑白名单规则
      const pathname = window.location.pathname || "";
      const hash = window.location.hash || "";
      let index = hash.indexOf("?");
      index = index !== -1 ? index : hash.length - 1;
      const href = pathname + hash.substring(0, index);
      if (wmSetting) {
        const { wmUse, wmUseMobile, whitelist, blacklist } = wmSetting;
        const isMobile = ua.device === 'Mobile';
        const isOpen = (isMobile ? wmUseMobile : wmUse) === '1';
        const list: string[] = isOpen ? blacklist : whitelist;
        let showWm: boolean = isOpen;
        let listenRouteChange = false;
        try {
          if (list) {
            listenRouteChange = true;
            // 处理 ecode 自定义黑白名单，历史遗留
            const match = list.findIndex(value => new RegExp(value).test(href));
            if (match !== -1) {
              showWm = !isOpen;
            } else {
              showWm = isOpen;
            }
          } else if (isOpen) {
            // 处理人员黑白名单
            if (pageResourceList === 'PAGE_RESOURCE_WHITELIST') {
              showWm = showWm && pageResourceWhitelistContain;
            } else if (pageResourceList === 'PAGE_RESOURCE_BLACKLIST') {
              showWm = showWm && !pageResourceBlacklistContain;
            }
            // 处理ip黑白名单
            if (pageIpList === 'PAGE_IP_WHITELIST') {
              showWm = showWm && pageIpWhitelistContain;
            } else if (pageIpList === 'PAGE_IP_BLACKLIST') {
              showWm = showWm && !pageIpBlacklistContain;
            }
            // 处理网页黑白名单
            if (pagePageList === 'PAGE_PAGE_WHITELIST') {
              listenRouteChange = pagePageWhitelist?.length;
              showWm = showWm && pagePageWhitelist?.length && pagePageWhitelist.findIndex((value: string) => new RegExp(value).test(href)) !== -1;
            } else if (pagePageList === 'PAGE_PAGE_BLACKLIST') {
              listenRouteChange = pagePageBlacklist?.length;
              showWm = showWm && (!pagePageBlacklist?.length || pagePageBlacklist.findIndex((value: string) => new RegExp(value).test(href)) === -1);
            }
          }
          if (listenRouteChange && !this.unlistener) {
            this.listenRouteChange();
          }
          if (showWm) {
            this.setWMOptions(wmSetting);
          } else {
            this.destory();
          }
        } catch(e) {
          console?.error(e);
        }
      }
    });
  }

  // 监听路由变化
  listenRouteChange = () => {
    this.unlistener = history.listen(() => {
      let timer = setTimeout(() => {
        // 路由跳转地址栏变化存在延迟，需要延迟路由判断
        clearTimeout(timer);
        this.loadSystemWatermark();
      }, 200);
    });
  }
}

let systemWatermarkSetting: WatermarkSetting;
try {
  // 加载系统设置水印
  systemWatermarkSetting = new WatermarkSetting();
} catch (error) {
  console?.error(error);
}

export { systemWatermarkSetting };

export default WatermarkSetting;