import { isObservable, toJS } from "mobx";
import { AnyObj, FormDatas, FormInitAllDatas, FormItemProps, FormLayoutType } from "../../../lib";
import { request, RequestOptionConfig } from "@weapp/utils";
import { BROWSERESULTCONST } from "../../browser/PropsType";

/** 动态调整 id 列宽 */
export const getSelectionWidth = (total: number) => {
  const len = total.toString().length;
  // 数据与宽度
  // 数据长度大于 4，每一位增加 10 px
  return Math.max(50, 20 + len * 10).toString();
}

/**
 * 获取编辑布局
 * @param condition 编辑 condition（后台只更新了 data，layout 布局需要重新补充下）
 * @param module 模块标识
 * @returns condition
 */
export const getEditCondition = async (condition: FormInitAllDatas, params?: AnyObj) => {
  const { module, showVoiceToTextBtn } = params || {};
  const { data, layout, items } = condition;
  let _data: FormDatas = isObservable(data) ? toJS(data) : data, _layout: FormLayoutType[] = [], _items: FormItemProps = { ...items };
  const keys = Object.keys(data);

  try {
    // 获取新的 items（将对象类型/对象设置为只读，更新 browser 语音转文字设置）
    const reg = /objType|obj_id/
    for (const key in _items) {
      if (Object.prototype.hasOwnProperty.call(_items, key)) {
        if (key.match(reg)) {
          _items[key] = {..._items[key], readOnly: true };
          // _items[key].readOnly = true;
        } else if (_items[key].itemType === 'BROWSER') {
          if (showVoiceToTextBtn && !_items[key].readOnly && !_items[key].disabled) {
            // _items[key].showVoiceToTextBtn = showVoiceToTextBtn;
            _items[key] = {..._items[key], showVoiceToTextBtn };
          }
        }
      }
    }

    // 获取新的 data（主要是通过浏览按钮接口去获取完整的浏览按钮数据）
    for (let index = 0; index < keys.length; index++) {
      const key = keys[index];
      if (items[key]?.itemType === 'BROWSER' && (data[key] as any[]).length > 0) {
        let selectIds: string = '';
        if (typeof (data[key] as any[])[0] === 'string') {
          selectIds = (data[key] as []).join(',') || (data.targetId as string);
        } else {
          selectIds = (data[key] as []).map((item: any) => item.id).join(',')
        }
        const config: RequestOptionConfig = {
          url: `/api/${items[key]?.browserBean?.module}/common/browser/destData/${items[key]?.browserBean?.type}`,
          method: 'POST',
          data: {
            [BROWSERESULTCONST.BROWSER_MULT_DEST_SELECTIDS]: selectIds,
          }
        }
        // 1. 接口通过 id 获取浏览按钮数据
        const res_browser = await request(config);
        // 2. 填充浏览按钮数据
        _data[key] = res_browser?.data?.data || [];
      }
    }

    // 通过 objType 配置联动展示效果(Form 初始化不会执行 cascadeRules，因此编辑时需要特殊处理下)
    let type = data['objType'] as string;
    const objTypeLayout = layout.filter(ly => ly[0].id === 'objType');
    // 多行字段联动信息（objType）
    const { show, hide } = objTypeLayout?.[0]?.[0]?.['cascadeRulesOuter']?.['objType'][type];
    // 获取新的 layout
    _layout = layout.map((array: any) => {
      return array.map((l: any) => {
        // 通过 objTypeLayout 的联动配置，来调整初始布局
        let _l = { ...l }
        if (show.includes(_l.id)) {
          _l.hide = false
        } else if (hide.includes(_l.id)) {
          _l.hide = true;
        }
        // 简单行内字段联动配置
        if (_l.id !== 'objType' && _l.items?.length && _l.cascadeRules) {
          // cascadeRules 的 逻辑是根据 items 顺序查找
          const cascadeRules = _l['cascadeRules'];

          let index = 0;
          let newItems: string[] = [];
          let preItmes = _l['items'];

          // 从 preItmes 的第一个 item 开始，将当前的 item 放入 newItems 中
          // 如果有匹配到联动，就调整 preItems，然后匹配下一位 preItems 的 item
          while (index < preItmes.length) {
            const itemKey = preItmes[index];
            newItems.push(itemKey);
            const dataKey = data[itemKey] as string;
            if (dataKey && itemKey in cascadeRules && dataKey in cascadeRules[itemKey]) {
              const option = cascadeRules[itemKey][dataKey];
              const cascadeItems = Array.isArray(option) ? option : (option?.['items'] || []);
              preItmes = newItems.concat(cascadeItems);
            }
            index++;
          }
          _l['items'] = newItems;
        }
        return _l;
      })
    })

    // 返回新的 condition
    let _condition: FormInitAllDatas = {
      ...condition,
      data: _data,
      layout: _layout,
      items: _items
    }

    return _condition;
  } catch (error) {
    throw error;
  }
}